@import "settings/colors";
@import "settings/mixins";
@import "settings/variables";
@import 'settings';

// --- Dependencies ---
@import '../../node_modules/foundation-sites/scss/vendor/normalize';
@import '../../node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/helpers/missing-dependencies';
@import '../../node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/helpers/true';
@import '../../node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/contain';
@import '../../node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/purge';
@import '../../node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/remove';
@import '../../node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/replace';
@import '../../node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/to-list';


// --- Components ---
// Utilities
@import '../../node_modules/foundation-sites/scss/util/util';
// Global styles
@import '../../node_modules/foundation-sites/scss/global';
@import '../../node_modules/foundation-sites/scss/forms/forms';
@import '../../node_modules/foundation-sites/scss/typography/typography';


// Generic components
@import '../../node_modules/foundation-sites/scss/components/button';


@mixin foundation-everything($flex: true,
	$prototype: false,
	$xy-grid: $xy-grid) {
	@if $flex {
		$global-flexbox: true !global;
	}

	@if $xy-grid {
		$xy-grid: true !global;
	}

	// Global styles
	@include foundation-global-styles;
	@include foundation-forms;
	@include foundation-typography;

	// Generic components
	@include foundation-button;
}



.large-align-left {
	@include breakpoint(large up) {
		justify-content: flex-start;
	}
}

.large-align-right {
	@include breakpoint(large up) {
		justify-content: flex-end;
	}
}


@include foundation-global-styles;
@include foundation-typography;
@include foundation-button;


/* Page de login d'admin */
body.login {
	div#login h1 a {
		background: url(../images/logo_guiu-ip.svg) 0 0 no-repeat;
		background-size: cover;
		width: toRem(238);
		height: auto;
		aspect-ratio: 238/75;
		margin: 0 auto;
	}

	&.login h1.admin-email__heading{
		@include fontTitle;
		font-size: 30px;
	}

	p{
		font-size: inherit;
	}
}

$admin-font : -apple-system, BlinkMacSystemFont, "Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;

// Admin sidebar
#adminmenu {
	.wp-submenu-head,
	a.menu-top,
	button {
		font-family: $admin-font;
	}

	.wp-submenu li {
		a {
			font-family: $admin-font;
		}

		&.curent {
			a {
				font-weight: 600;
			}
		}
	}
}

.interface-complementary-area.edit-post-sidebar{
	font-family: $admin-font;

	*,
	a,
	button{
		font-family: inherit;
	}
}


.components-panel__header.interface-complementary-area-header.edit-post-sidebar__panel-tabs,
#adminmenu {
	ul {
		margin: 0;

		li {
			list-style: none;
		}
	}
}

// Remet la font et border-radius originaux de l'admin sur les labels et champs acf
#editor {
	.inside.acf-fields>.acf-field-repeater,
	.inside.acf-fields>.acf-field-group,
	.inside.acf-fields>.acf-field-wysiwyg,
	.inside.acf-fields>.acf-field-clone {
		&>.acf-label {
			font-size: $fontM;
		}
	}

	.acf-field{
		input[type=text], 
		input[type=password],
		input[type=date], 
		input[type=datetime], 
		input[type=datetime-local], 
		input[type=email], 
		input[type=month], 
		input[type=number], 
		input[type=search], 
		input[type=tel], 
		input[type=time], 
		input[type=url], 
		input[type=week], 
		textarea, 
		select{
			border-radius: 0;
		}
	}

	.wp-switch-editor{
		border-radius: 0;
	}

	.acf-link .link-wrap,
	.acf-file-uploader,
	.acf-relationship{
		font-family: $admin-font;

		*,
		a,
		button{
			font-family: inherit;
		}
	}
}

.media-modal-content{
	.media-router{
		button{
			border-radius: 0;
		}
	}
}

#tinymce {
	padding: toRem(10);

	a{
		color: $primary;
		text-decoration: underline;
	}

	ul {
        margin-bottom: toRem(20);
        margin-left: 0;
		
		li{
			position: relative;
            margin-bottom: toRem(10);
			list-style: none;

			&:before{
				content: '';
				background: $secondary;
				display: inline-block;
				vertical-align: middle;
				width: toRem(4);
				height: toRem(4);
				margin-top: -2px;
				margin-right: toRem(6);
                border-radius: 100%;
			}

			ul{
				margin-top: toRem(10);
			}

			li{
				padding-left: toRem(20);

				&:before{
					content: '';
					background: $secondary;
					display: inline-block;
					vertical-align: middle;
					width: toRem(12);
					height: toRem(12);
					margin-top: -4px;
					margin-right: toRem(10);
					border-radius: 100%;
				}
			}
		}
	}

	.button.secondary{
		background: none!important;
		width: fit-content;
		height: toRem(50);
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 2;
		padding: rem-calc(0 30 2);
		border-width: 2px;
		border-style: solid;
		font-size: $fontS;
		line-height: 1;
		border-radius: 5px;
		transition: all 0.25s ease;
	
		@include breakpoint(small down){
			width: 100%;
			height: auto;
			padding: rem-calc(10 20);
		}
	
		&::after{
			content: '';
			position: absolute;
			bottom: 0;
			left: -1px;
			right: -1px;
			z-index: -1;
			height: 100%;
			transform-origin: bottom center;
			transform: scaleY(0.05);
			transition: transform 0.25s ease;
		}

		$buttonsColors : (
			'primary': $primary,
			'secondary': $secondary,
		);

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				&::after{
					transform: scaleY(1);
				}
			}
		}

		@each $nom, $valeur in $buttonsColors {
			&.#{$nom}{
				border-color: $valeur;
				color: $valeur;
	
				&::after{
					background-color: $valeur;
				}
	
				@media (hover: hover) and (pointer: fine) {
					&:hover {
						color: white;
					}
				}
			}
		}
	}
}

// Override le style des textes Gutenberg
.edit-post-visual-editor{
	.editor-styles-wrapper {
		h1, h2, h3{
			@include fontTitle;
		}

		.wp-element-button,
		.wp-block-button__link {
			@extend .button;
			background-color: $primary;
			width: fit-content;
			height: toRem(50);
			display: flex;
			align-items: center;
			justify-content: center;
			padding: rem-calc(0 40);
			line-height: 1;
			text-transform: uppercase;
			border-radius: 100px;
		}

		ul.wp-block-list{
			margin-bottom: toRem(30);
			margin-left: 0;
			padding-left: toRem(35);
			
			li.wp-block-list-item{
				position: relative;
				margin-bottom: toRem(10);
				list-style: none;
				padding-left: toRem(30);
	
				&:before{
					content: '';
					background: $primary;
					position: absolute;
					top: 0;
					left: 0;
					width: toRem(10);
					height: toRem(10);
					margin-top: 8px;
					border-radius: 100%;
				}
			}
		}

		img,
		figure{
			&.aligncenter{				
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.wp-block-image{
			width: fit-content;
			margin-bottom: toRem(20);
		}

		.wp-block-gallery,
		.wp-block-embed,
		.wp-block-video{
			margin-bottom: toRem(40);
		}
	}
}


#wpwrap{
	
	// Page FAQ
	// on masque le sélecteur de catégories qui sert à être cloné dans les items faq
	#acf-group_6659c455385f1 > .acf-fields > [data-name="categories"]{
		display: none;
	}
}