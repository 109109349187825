/* --- COLORS --- */
$black: #231f20;
$white: white;
$primary: #1B3640;
$secondary: #AC9382;
$secondaryLight: #D5C5BC;
$blue: #38516D;
$orange: #D53B05;
$grey: #a9aec5;
$greyDark: #414141;
$greyLight: #F3F4F5;
$error: #be2020;
$success: #a2cc52;

$colors: (
    'black': $black,
    'white': white,
    'primary': $primary,
    'secondary': $secondary,
    'secondaryLight': $secondaryLight,
    'orange': $orange,
    'error': $error,
    'success': $success,
    'grey': $grey,
    'greyDark': $greyDark,
    'greyLight': $greyLight,
    'blue': $blue,
);

$buttonsColors : (
    'primary': $primary,
    'blue': $blue,
    'secondary': $secondary,
    'secondaryLight': $secondaryLight,
    'orange': $orange,
    'white': $white,
);


@function color($key) {
    @if map-has-key($colors, $key) {
        @return map-get($colors, $key);
    }

    @warn "Unknown `#{$key}` in $colors.";
    @return null;
}