//  Foundation for Sites Settings
@import '../../node_modules/foundation-sites/scss/util/util';

// 1. Global
// ---------

$global-font-size: rem-calc(15);
$global-width: rem-calc(1520);
$global-lineheight: 1.2;
$foundation-palette: (
  primary: $primary,
  secondary: $secondary,
  black : $black,
  success : $success,
  warning : #ffae00,
  alert : $error,
);
$light-gray: #e6e6e6;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$black: $black;
$white: white;
$body-background: #FFFFFF;
$body-font-color: $primary;
$body-font-family: 'Montserrat', sans-serif;
$body-safe-padding: false;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 16px;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;
$print-hrefs: true;

@include add-foundation-colors;

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: $xxLarge,
);
$breakpoints-hidpi: (
  hidpi-1: 1,
  hidpi-1-5: 1.5,
  hidpi-2: 2,
  retina: 2,
  hidpi-3: 3
);
$print-breakpoint: large;
$breakpoint-classes: (
  small medium large xlarge xxlarge
);

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 20px,
  medium: 30px,
);
$grid-column-align-edge: true;
$grid-column-alias: 'columns';
$block-grid-max: 8;

// 4. Base Typography
// ------------------

$header-font-family: 'Montserrat', sans-serif;
$header-font-weight: normal;
$header-font-style: normal;
$font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;
$header-color: $black;
$header-lineheight: 1.05;
$header-margin-bottom: toRem(30);
$header-styles: (
  small: (
    'h1': ('font-size': 28),
    'h2': ('font-size': 24, 'line-height': 1.3),
    'h3': ('font-size': 18),
    'h4': ('font-size': 16, 'line-height': 1.3),
    'p': ('font-size': 14, 'lh': 1.5)
  ),
  medium: (
    'h1': ('font-size': 40),
    'h2': ('font-size': 30),
  ),
  large: (
    'h1': ('font-size': 50),
    'h2': ('font-size': 40),
    'h3': ('font-size': 20, 'line-height': 1),
    'h4': ('font-size': 16),
    'p': ('font-size': 15)
  ),
  xlarge: (
    'h1': ('font-size': 55, 'line-height': 1.15),
    'h2': ('font-size': 45),
    'h3': ('font-size': 25, 'lh': 1.2),
    'p': ('lh': 1.47)
  )
);
$header-text-rendering: optimizeLegibility;
$small-font-size: 80%;
$header-small-font-color: $medium-gray;
$paragraph-lineheight: 1.6;
$paragraph-margin-bottom: 1rem;
$paragraph-text-rendering: optimizeLegibility;
$enable-code-inline: true;
$anchor-color: $primary-color;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$hr-width: $global-width;
$hr-border: 1px solid $medium-gray;
$hr-margin: rem-calc(20) auto;
$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: outside;
$list-side-margin: 1.25rem;
$list-nested-side-margin: 1.25rem;
$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0.3rem;
$blockquote-color: $dark-gray;
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $medium-gray;
$enable-cite-block: true;
$keystroke-font: $font-family-monospace;
$keystroke-color: $black;
$keystroke-background: $light-gray;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted $black;


// 11. Button
// ----------

$button-font-family: inherit;
$button-font-weight: $fwRegular;
$button-padding: rem-calc(0 30);
$button-margin: 0 0 $global-margin 0;
$button-fill: solid;
$button-background: $primary-color;
$button-background-hover: scale-color($button-background, $lightness: -15%);
$button-color: white;
$button-color-alt: $black;
$button-radius: 5px;
$button-border: none;
$button-hollow-border-width: 2px;
$button-palette: $foundation-palette;
$button-opacity-disabled: 0.25;
$button-background-hover-lightness: -20%;
$button-hollow-hover-lightness: -50%;
$button-transition: background-color 0.25s ease-out,
  color 0.25s ease-out;
$button-responsive-expanded: false;


// 19. Flexbox Utilities
// ---------------------

$flex-source-ordering-count: 6;
$flexbox-responsive-breakpoints: true;

// 20. Forms
// ---------

$fieldset-border: 1px solid $medium-gray;
$fieldset-padding: rem-calc(20);
$fieldset-margin: rem-calc(18 0);
$legend-padding: rem-calc(0 3);
$form-spacing: rem-calc(16);
$helptext-color: $black;
$helptext-font-size: rem-calc(13);
$helptext-font-style: italic;
$input-prefix-color: $black;
$input-prefix-background: $light-gray;
$input-prefix-border: 1px solid $medium-gray;
$input-prefix-padding: 1rem;
$form-label-color: $black;
$form-label-font-size: rem-calc(17);
$form-label-font-weight: $global-weight-normal;
$form-label-line-height: 1.4;
$select-background: $white;
$select-triangle-color: $black;
$select-radius: 0;
$input-color: $black;
$input-placeholder-color: $greyDark;
$input-font-family: inherit;
$input-font-size: rem-calc(17);
$input-font-weight: $global-weight-normal;
$input-line-height: $global-lineheight;
$input-background: transparent;
$input-background-focus: #fff;
$input-background-disabled: $light-gray;
$input-border: 1px solid #CBCBCB;
$input-border-focus: 1px solid $grey;
$input-padding: 0 1rem;
$input-shadow: none;
$input-shadow-focus: none;
$input-cursor-disabled: not-allowed;
$input-transition: border-radius 0.25s ease,
  border 0.25s ease;
$input-number-spinners: true;
$input-radius: 0;
$form-button-radius: $global-radius;

// 21. Label
// ---------

$label-background: $primary-color;
$label-color: $white;
$label-color-alt: $black;
$label-palette: $foundation-palette;
$label-font-size: 0.8rem;
$label-padding: 0.33333rem 0.5rem;
$label-radius: $global-radius;


// 56. Xy Grid 
// ----------- 
$xy-grid: true;
$grid-container: $global-width;
$grid-columns: 12;
$grid-margin-gutters: (
  small: 30px,
  medium: 30px,
  large: 40px,
);
$grid-padding-gutters: $grid-margin-gutters;
$grid-container-padding: $grid-padding-gutters;
$grid-container-max: $global-width;
$xy-block-grid-max: 8;